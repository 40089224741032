import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../images/what-to-expect.jpg"
import Layout from "../components/layoutMainCustom"
import PageHeaders from "../components/pageHeaders"
import Countdown from "../components/countdown"
import { Button, Collapse, Stack, Box } from "@chakra-ui/core"
import SEO from "../components/seo"
import Head from "../components/CovidHead"

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
`

const Heading = styled.h2`
  font-weight: 600;
  text-decoration: none;
  font-family: "Montserrat";
  color: #2d3748;
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  letter-spacing: 0;
  text-transform: uppercase;
`

function ListItem({ ...rest }) {
  return <Box p={5} shadow="md" borderWidth="1px" {...rest}></Box>
}

function IndexPage() {
  const [show, setShow] = React.useState(false)
  const [show1, setShow1] = React.useState(false)
  const [show2, setShow2] = React.useState(false)

  const handleToggle1 = () => setShow1(!show1)
  const handleToggle = () => setShow(!show)
  const handleToggle2 = () => setShow2(!show2)

  return (
    <Layout ImgSrc={BgImage} MainText="Re-Gathering Plan During COVID-19">
      <Head />
      <SEO title="Re-Gathering Plan During COVID-19" />
      <PageHeaders
        css={css`
          display: flex;
        `}
      >
        Re-Gathering Plan During COVID-19
      </PageHeaders>
      <HeaderText></HeaderText>
      <ParagraphText>
        During this time of ongoing concern regarding the COVID-19 virus it is
        important that we be proactive as the family of faith in our community
        and not fall prey to an overwhelming spirit of fear. We now as always
        can trust in the Lord Jesus Christ who has all power, all wisdom &
        knowledge, and is always with us. This has not caught Him by surprise.
      </ParagraphText>
      <br />
      <ParagraphText>
        At Cross Church, our goal will be not to overreact as a church or not to
        under react as well because the concerns are a reality we face and there
        are those within our community that face this concern with a higher risk
        to their health. Our desire is to work with the suggestion of our local
        and federal government of helping slow the spread of this virus while
        maintaining our religious liberty to freely & safely gather for worship,
        fellowship, discipleship, ministry, & gospel proclamation.
      </ParagraphText>
      <PageHeaders
        css={css`
          display: flex;
          margin-top: 4rem;
        `}
      >
        Our Plan
      </PageHeaders>
      <HeaderText></HeaderText>
      <ParagraphText>Here is our plan for in-person services:</ParagraphText>
      <br />
      <br />
      <Stack spacing={8}>
        <ListItem>
          <Heading>Update to Weekend Services</Heading>
          <ParagraphText>
            In-Person Services have resumed on our Surprise and Phoenix campuses
            with the below measures:
            <br />
            <ul>
              <li>
                Enhanced and more frequent cleaning and sanitization areas.
              </li>
              <li>
                Limited capacity in our auditoriums and spaced out seating to
                accomodate physical distancing.
              </li>
              <li>
                Added hand sanitizer stations at key entry points for ease of
                access.
              </li>
              <li>
                Masks for everyone 6 and over per{" "}
                <a
                  href="https://www.maricopa.gov/5489/Protect-Yourself-and-Others"
                  target="_blank"
                >
                  Maricopa County Guidelines.
                </a>
              </li>
              <li>
                Temperature checks for all Volunteers and Kids prior to check-in
                for Cross Church Kids Ministries.
              </li>
            </ul>
            <br />
            <a
              href="https://www.youtube.com/watch?v=I71dxIv4KCk"
              target="_blank"
            >
              Cross Church Phoenix Re-Gathering Update
            </a>
            <br />
            <a
              href="https://www.facebook.com/watch/?v=915339235653972"
              target="_blank"
            >
              Cross Church Surprise Re-Gathering Update
            </a>
          </ParagraphText>
          <ParagraphText
            css={css`
              width: 40%;
              @media (max-width: 940px) {
                width: 100%;
              }
            `}
          >
            <HeaderText>
              Watch Services Online{" "}
              <Button variantColor="orange" onClick={handleToggle} ml={4}>
                {show ? "↑" : "↓"}
                &nbsp;&nbsp;VIEW LINKS
              </Button>
            </HeaderText>
            <Collapse mt={4} isOpen={show}>
              <a href="https:/fb.com/crosschurchsurprise/live" target="_blank">
                Watch Surprise Campus Live
              </a>
              <br />
              <a href="https://fb.com/crosschurchphx/live" target="_blank">
                Watch Phoenix Campus Live
              </a>
            </Collapse>
          </ParagraphText>
        </ListItem>
        <ListItem>
          <ParagraphText>
            <Heading>Additional Meetings</Heading>
            Celebrate Recovery at our Phoenix Campus has resumed meeting
            in-person.
          </ParagraphText>
        </ListItem>
        <ListItem>
          <Heading>Giving During This Time</Heading>
          <ParagraphText>
            Your gifts are still vitally important to the health of our church
            and there are a number of ways to give outside of our on-campus
            service.
            <br />
            <br />
            - Mailing your offering to Cross Church at 17475 W Bell Rd Surprise,
            AZ 85374
            <br />-{" "}
            <a href="https://pushpay.com/pay/palmvistasurprise/3wVi2Zku2LnNwZiQ57OSwAgive">
              Online giving portal on our website.
            </a>
            <br />- Text CROSSCHURCH to 940-00.
            <br />- In-person at Giving Box in the back of Worship Rooms.
            <br />
            <br />
            As you know, the work of the ministry requires the vital
            contributions of our church family.
          </ParagraphText>
        </ListItem>
        <ListItem>
          <Heading>Office Hours</Heading>
          <ParagraphText>
            Our offices will still be open and available for in-person scheduled
            meetings. We are taking proper precautions in cleaning and
            sanitizing all surfaces through our buildings.
          </ParagraphText>
        </ListItem>
        <ListItem>
          <Heading>Helping Those In Need</Heading>
          <ParagraphText>
            If you are in need of help in this difficult time, we are accepting
            benevolence requests and have a Food Pantry open on our Phoenix
            Campus.
            <br />
            <br />
            The Food Pantry located at our Phoenix Campus is open on Tuesdays,
            Thursdays, and Fridays from 9am - 11:30am. To drop off essentials
            for people in need, please bring those to the Phoenix Campus on
            Tuesday from 9am - 4pm.
          </ParagraphText>
        </ListItem>
        <ListItem>
          <Heading>More Information about COVID-19</Heading>
          <ParagraphText>
            Please use the following resources below for information about
            protecting yourself and others.
            <br />
            <br />
            <a href="https://www.azdhs.gov/preparedness/epidemiology-disease-control/infectious-disease-epidemiology/index.php#novel-coronavirus-home">
              Arizona Health Services Website
            </a>
            <br />
            <a href="https://www.cdc.gov/coronavirus/2019-ncov/index.html">
              CDC Website
            </a>
            <br />
            <a href="https://www.cdc.gov/coronavirus/2019-ncov/downloads/stop-the-spread-of-germs.pdf">
              Stop the Spread of Germs
            </a>
          </ParagraphText>
        </ListItem>
      </Stack>
      <PageHeaders
        css={css`
          display: flex;
          margin-top: 4rem;
        `}
      >
        Final Thoughts
      </PageHeaders>
      <HeaderText></HeaderText>
      <ParagraphText>
        We are monitoring this situation closely, and are continuing to make
        plans based on the well-being of our church family and community. Please
        pray for wisdom for our church leaders and for us as a people of faith
        to use wisdom, truth, love, and grace to share the Gospel.
        <br />
        <br />
        This world needs hope and we know that hope has a name… Jesus. Let us
        love and make Jesus known to the world around us!
      </ParagraphText>
    </Layout>
  )
}

export default IndexPage
